<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="reset()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click="save()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text class="pt-5">
            <v-text-field
                :label="$store.getters.translate('name')"
                v-model="record.name"/>
            <v-select
                :label="$store.getters.translate('model_type')"
                v-model="record.model_type"
                :items="$lodash.sortBy(models, 'text')"/>
            <v-select
                :label="$store.getters.translate('linked_model')"
                v-model="record.linked_model"
                :items="$lodash.sortBy(models, 'text')"
                clearable/>
            <v-select
                :label="$store.getters.translate('event')"
                v-model="record.event"
                :items="getEvents"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import models from "./models.js";
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("workflow"),
        name: "workflows",
        model: 'workflow'
      },
      loading: false,
      record: {},
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.record = {};
      this.openModal(this.modal_name);
    },
    async save() {
      this.loading = true;
      let id = await helpFunctions.createModel(this.$toasted, this.page.name, this.record);
      if(id) {
        await this.$router.push("/workflows/" + id);
      }
      this.loading = false;
    },
    reset() {
      this.record = {};
      this.closeModal(this.modal_name);
    },
  },
  computed: {
    models() {
      return models;
    },
    getEvents() {
      return this.record.model_type ? this.$lodash.sortBy(this.models.find((model) => model.value == this.record.model_type).children, "text") : [];
    },
  },
};
</script>