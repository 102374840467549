<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @create="create" @edit="edit"/>
      </v-col>
    </v-row>
    <create-workflow modal_name="create_workflow" ref="create_workflow" @refresh="reload"/>
  </v-container>
</template>

<script>
import BaseTable from "../../components/commonComponents/BaseTable";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import CreateWorkflow from "./Create";
export default {
  components: {
    BaseBreadcrumb,
    BaseTable,
    CreateWorkflow
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("workflows"),
        name: "workflows",
        model: "workflow",
      },
    };
  },
  methods: {
    create() {
      this.$refs.create_workflow.load();
    },
    edit(item) {
      this.$router.push("/" + this.page.name + "/" + item.id);
    },
    reload() {
      this.$refs.base_table.run();
    },
  },
};
</script>
